import { css, SerializedStyles } from '@emotion/core'

export const colorSchemeStyles: Record<string, undefined | SerializedStyles> = {
  light: css`
    --text-color: var(--brandblue-main);
    --border-color: var(--gray-4);
    --site-background: var(--color-white);
    --standerat-background: var(--color-beige-bright-3, var(--beige-bright-3));

    --liveindicator: var(--brandblue-main);

    --gradient-to-transparent-ltr: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0.7) 50%, hsla(0, 0%, 100%, 0));
    --gradient-to-transparent-rtl: linear-gradient(270deg, #fff 0, hsla(0, 0%, 100%, 0.7) 50%, hsla(0, 0%, 100%, 0));
    --button-background: var(--color-brandblue-main, var(--brandblue-main));

    --secondary-button-background: var(--color-beige-bright-2, var(--beige-bright-2));
    --secondary-icon-button-background: var(--color-beige-bright-2, var(--beige-bright-2));
    --tile-background: var(--color-gray-gray-6, var(--gray-6));

    --search-input-color: var(--color-gray-gray-2, var(--gray-2));
    --search-input-background: var(--color-beige-bright-3, var(--beige-bright-3));
    --search-input-placeholder-color: var(--color-gray-gray-2, var(--gray-2));

    --gray-color: var(--color-gray-gray-3, var(--gray-3));

    --sub-header: var(--color-gray-gray-2, var(--gray-2));
    --tab: var(--color-beige-bright-2, var(--beige-bright-2));
    --seat: #202346;
    --seat-content: white;
    --input-border: var(--color-brandblue-main, var(--brandblue-main));
    --council-of-states-background: #f8f7f4;
    --tab-active: #202346;
    --tab-color-active: white;
    --municipalities-search-input: var(--beige-bright-3);
    --municipalities-party-name: var(--color-brandblue-main, var(--brandblue-main));
    --majorityThreshold: var(--text-color);
    --check-box: var(--brandblue-main);

    --banner-background: var(--brandblue-bright-5);
    --banner-button-color: white;
  `,
  dark: css`
    --text-color: var(--gray-5);
    --border-color: var(--gray-4);
    --site-background: var(--gray-1);
    --standerat-background: #474a66;

    --liveindicator: var(--brandblue-bright-3);

    --gradient-to-transparent-ltr: linear-gradient(90deg, #121212 0, rgba(18 18 18 / 0.7) 50%, rgba(18 18 18 / 0));
    --gradient-to-transparent-rtl: linear-gradient(270deg, #121212 0, rgba(18 18 18 / 0.7) 50%, rgba(18 18 18 / 0));

    --button-background: var(--color-yellow-main, var(--yellow-main));
    --secondary-button-background: var(--color-brandblue-bright-2, var(--brandblue-bright-2));
    --secondary-icon-button-background: var(--color-beige-bright-2, , var(--beige-bright-2));
    --tile-background: var(--color-gray-gray-2, var(--gray-2));

    --search-input-color: var(--color-brandblue-main, var(--brandblue-main));
    --search-input-background: white;
    --search-input-placeholder-color: var(--color-gray-gray-2, var(--gray-2));

    --gray-color: var(--text-color);

    --sub-header: white;
    --tab: #6f7187;
    --seat: white;
    --seat-content: #202346;
    --input-border: #ffe462;
    --council-of-states-background: #474a66;
    --tab-active: #ffe462;
    --tab-color-active: var(--color-brandblue-main, var(--brandblue-main));
    --municipalities-search-input: white;
    --municipalities-party-name: white;
    --majorityThreshold: white;
    --check-box: var(--gray-5);

    --banner-background: var(--brandblue-bright-1);
    --banner-button-color: var(--brandblue-main);
  `,
}
