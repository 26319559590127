import { rgb } from 'd3-color'
import { scaleQuantize, scaleThreshold } from 'd3-scale'
import * as D from '@/domain'
import { caseOutcome, Outcome, Percent, unPercent, caseBallotVariant } from '../domain/index'
import { red, blue, divergeA, divergeB, grey } from './colors/raw'
import { ColorSpaces } from 'chroma-js'
import { COLORS } from '@ta-interaktiv/react-components'

export * from './colors/raw'

/**
 * @deprecated Import type from '@/domain'
 */
export type BallotVariant = D.BallotVariant

export type CSSColor = string
export type DivergingColors = [CSSColor, CSSColor]

export const white = '#ffffff'
export const black = '#000000'
export const isDarkmode =
  typeof window === 'undefined' ? false : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
export const darkBackground = 'var(--color-gray-gray-1)'

// ----------------------------------------------------------------------------
// Text colors

export const text: CSSColor = isDarkmode ? '#E9E9E9' : 'var(--brandblue-main)'
export const lightGrey: CSSColor = grey['400']

export const highlight: CSSColor = blue['700']

// ----------------------------------------------------------------------------
// TA colors

export const taBlue: CSSColor = '#007abf'
export const taBlueDarker: CSSColor = '#02659c'

// ----------------------------------------------------------------------------
// yae / nay

export const electionYea = isDarkmode ? '#74DB96' : '#479260'
export const electionNay = isDarkmode ? '#F66771' : red['700']
export const electionTie = isDarkmode ? '#D4D4D4' : grey['700']

export const yea: CSSColor = `var(--tx-sys-color-yea-main, ${blue['700']})`
export const nay: CSSColor = `var(--tx-sys-color-nay-main, ${isDarkmode ? red['600'] : red['700']})`

// ----------------------------------------------------------------------------
// secondary yae / nay

export const yeaSecondary: CSSColor = divergeA['700']
export const naySecondary: CSSColor = divergeB['700']

export const outcomeToColor = (outcome: Outcome, variant: BallotVariant): CSSColor => {
  const [a, b] = caseBallotVariant(variant, { default: [yea, nay], tieBreak: [yeaSecondary, naySecondary] })
  return caseOutcome({ yea: a, nay: b })(outcome)
}

export const percentColorScale = (variant: BallotVariant) => {
  const [a, b] = pickDivergingScale(variant)
  return scaleThreshold<number, string>()
    .domain([0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7])
    .range(
      [a['800'], a['700'], a['500'], a['400'], a['200'], b['200'], b['400'], b['500'], b['700'], b['800']].reverse(),
    )
}

export const percentToColor = (p: Percent, variant: BallotVariant): CSSColor => percentColorScale(variant)(unPercent(p))

export function pickDivergingMain(variant: BallotVariant): DivergingColors {
  return caseBallotVariant<DivergingColors>(variant, {
    default: [yea, nay],
    tieBreak: [yeaSecondary, naySecondary],
  })
}

function pickDivergingScale(variant: BallotVariant) {
  return caseBallotVariant(variant, {
    default: [blue, red],
    tieBreak: [divergeA, divergeB],
  })
}

export const percentDifferenceColorScale = (variant: BallotVariant = 'default') => {
  const [a, b] = pickDivergingScale(variant)
  return scaleQuantize<string>()
    .domain([-1, +1])
    .range([b['800'], b['700'], b['500'], b['400'], a['400'], a['500'], a['700'], a['800']].reverse())
}

export function blendColor(c1: CSSColor, c2: CSSColor, a: number) {
  const { r: r1, g: g1, b: b1 } = rgb(c1)
  const { r: r2, g: g2, b: b2 } = rgb(c2)

  const a1 = 1 - a
  const a2 = a

  return rgb(r1 * a1 + r2 * a2, g1 * a1 + g2 * a2, b1 * a1 + b2 * a2)
}

export type PartyColorPalette = [CSSColor, CSSColor, CSSColor, CSSColor, CSSColor]
export function partyColorPalette(baseColor: CSSColor): PartyColorPalette {
  return [
    blendColor('#FFFFFF', baseColor, 0.25).hex(),
    blendColor('#FFFFFF', baseColor, 0.5).hex(),
    blendColor('#FFFFFF', baseColor, 0.75).hex(),
    baseColor,
    blendColor(baseColor, '#000000', 0.25).hex(),
  ]
}

/**
 * This color palette is used during loading, or when the real color is not available.
 */
export const fallbackPartyColorPalette = partyColorPalette('#999999')

export const hex2rgb = (hex = '#000000') => {
  const colors = hex.match(/\w\w/g)
  if (!colors) {
    return [255, 255, 255]
  }
  const [r, g, b] = colors.map((x) => parseInt(x, 16))
  return [r, g, b]
}

export const normalizeScale = (denomalizedRGBA: ColorSpaces['rgba']) => {
  const [r, g, b, a] = denomalizedRGBA
  return [r, g, b, a * 255]
}

/* ----------------------------------------------------------------------------
 * Colors used on maps (lakes, outlines, no results, …)
 */

export const lakeColor = COLORS.gray.gray_5
